<template>
  <div
    class="card is-clickable"
    :style="{ backgroundColor: project.backgroundColor }"
    @click="handleClick"
  >
    <div class="card-content">
      <p
        class="title is-size-4-mobile has-text-centered"
        :style="{ color: project.titleColor }"
      >
        {{ project.title }}
      </p>
      <p
        class="subtitle is-size-6 has-text-centered"
        :style="{ color: project.subTitleColor }"
      >
        {{ project.tags }}
      </p>
    </div>
    <img
      class="card-img"
      :src="require(`@/assets/images/${project.backgroundImage}.jpg`)"
      alt="Placeholder image"
    />
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object
    }
  },
  methods: {
    handleClick() {
      window.location.href = this.project.link;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.title:hover {
  color: $quill-gray !important;
}

.card {
  border-radius: 0.5rem;
  position: relative;
  min-height: 350px;

  @media only screen and (max-width: 500px) {
    font-size: 18px;
    min-height: 250px;
  }

  @media only screen and (min-width: 700px) {
    min-height: 360px;
  }

  @media only screen and (min-width: 830px) {
    min-height: 390px;
  }

  @media only screen and (min-width: 900px) {
    min-height: 410px;
  }

  @media only screen and (min-width: 955px) {
    min-height: 430px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1215px) {
    min-height: 280px;
  }

  @media only screen and (min-width: 1216px) and (max-width: 1407px) {
    min-height: 320px;
  }

  @media only screen and (min-width: 1408px) {
    min-height: 350px;
  }
}

.card-img {
  position: absolute;
  width: 90%;
  bottom: 0;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
</style>
