<template>
  <section class="section">
    <div class="mt-5"></div>
    <div class="container">
      <div class="card">
        <div class="card-content">
          <p class="title is-size-3-mobile is-size-1">
            Projects
          </p>
          <p class="subtitle is-size-6-mobile">
            Here are some of my personal projects from the past year.
          </p>
          <div class="columns is-multiline">
            <div
              class="column is-12-mobile is-12-tablet is-6-desktop"
              v-for="(project, index) in projects"
              :key="index"
            >
              <project-card :project="project" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProjectCard from '@/components/ProjectCard';
import data from '@/data/projects.json';

export default {
  name: 'Project',
  data() {
    return {
      projects: data
    };
  },
  components: {
    ProjectCard
  }
};
</script>

<style lang="scss" scoped>
.card-content {
  margin: 2rem 4rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}
</style>
